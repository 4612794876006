@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Freeman&display=swap');
@import url('bootstrap-icons/font/bootstrap-icons.css');


nav .header a {
  color: #1c1c1c !important;
  /*font-weight: bolder !important;*/
}

nav .footer a {
  color: #adafb1 !important;
}

nav .header a:hover {
  color: #e30303 !important;
  text-decoration-color: #e30303 !important;
  text-decoration-line: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;

}


.link-button {
  font-weight: bolder !important;
  text-decoration: none !important;
  color: #222e85 !important;
  font-size: 10pt !important;
}

.link-button:hover {
  background-color: #f2fcff !important;
  text-underline: #d20c64 !important;
  text-decoration-line: underline !important;
  /*text-decoration-color: #fcb519 !important;*/
  text-decoration-thickness: 2px !important;
  text-underline-offset: 5px;
}

.link-button a:focus {
  background-color: #f2fcff !important;
  text-underline: #d20c64 !important;
  text-decoration-line: underline !important;
  /*text-decoration-color: #fcb519 !important;*/
  text-decoration-thickness: 2px !important;
  text-underline-offset: 5px;
}


.btn-default {
  background: #a50303 !important;
  border-color: #650303 !important;
}

.modal-header {
  background: #a50303 !important;
  border-color: #650303 !important;
  color: #FFFFFF !important;
}


.page-title {
  color: #595757;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 28pt;
}


.logo-red {
  padding: 0 !important;
  /*margin: 0 !important;*/
  color: #e30303;
  font-family: "Freeman", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20pt;
}

.logo-black {
  color: black;
  padding: 0 !important;
  /*margin: 0 !important;*/
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 23pt;
}

.logo-white {
  padding: 0 !important;
  margin: 0 !important;
  color: white;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 28pt;
}



.box {
  border: 1px solid #ccc;
  margin: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);  /* You can adjust this to fit your design preference */
  grid-auto-rows: minmax(20px, auto);
}



.g_id_signin {
  width: 100% !important;
}

.g_id_signin iframe {
  margin: 0 auto !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



